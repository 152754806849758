import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("smarto24token");
    if (storedToken) {
      setToken(storedToken);
      try {
        const decoded = jwt_decode(storedToken);
        
        // استخراج وقت انتهاء صلاحية التوكن
        // const expirationTimeInSeconds = decoded.exp;
        
        // // تحويل وقت الانتهاء من ثوانٍ إلى تاريخ ووقت
        // const expirationDate = new Date(expirationTimeInSeconds * 1000);

        // // عرض وقت وتاريخ انتهاء صلاحية التوكن في الكونسول
        // console.log("Token will expire on:", expirationDate.toLocaleString());

        const userInfo = {
          UserName: decoded.UserInfo.username,
          EnterpName: decoded.UserInfo.EnterpName,
          role: [decoded.UserInfo.roles],
          DisplayName: decoded.UserInfo.DisplayName,
          EnterpPhone: decoded.UserInfo.EnterpPhone,
          EnterpAddress: decoded.UserInfo.EnterpAddress,
        };
        setUser(userInfo);
      } catch (error) {
        console.error("Error decoding token: ", error);
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, token }}>
      {children}
    </UserContext.Provider>
  );
};