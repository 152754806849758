import { useEffect } from "react";
import jwt_decode from "jwt-decode";

const TokenRefresher = () => {
  const serverURL = import.meta.env.VITE_REACT_APP_SERVER_URL;

  useEffect(() => {
    const refreshAccessToken = async () => {
      const storedToken = localStorage.getItem("smarto24token");

      if (storedToken) {
        try {
          const response = await fetch(`${serverURL}/refresh`, {
            method: "GET",
            credentials: "include",
          });
          //console.log("refresh request sent");

          if (!response.ok) {
            throw new Error("Failed to refresh access token");
          }

          const data = await response.json();
          const { accessToken } = data;

          localStorage.setItem("smarto24token", accessToken);
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      }
    };

    refreshAccessToken();

    const interval = setInterval(() => {
      refreshAccessToken();
    }, 300000);

    return () => clearInterval(interval);
  }, [serverURL]);

  return null;
};

export default TokenRefresher;
