import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import arLocale from "date-fns/locale/ar";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ResponsiveDrawer from "./components/ResponsiveDrawer/ResponsiveDrawer";
import { ThemeProvider } from "@mui/system";
import { UserProvider } from "./shared/_UserContext";
import { CenteredProgressGreen } from "./shared/_CenteredCircularProgress";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import TokenRefresher from "./components/Token/TokenRefresher";

import "./App.css";

const Login = lazy(() => import("./components/Login/Login"));

const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const Customers = lazy(() => import("./components/Stakeholders/Customers/Customers"));
const Guarantors = lazy(() => import("./components/Stakeholders/Guarantors/Guarantors"));
const Suppliers = lazy(() => import("./components/Stakeholders/Suppliers/Suppliers"));
const InvoiceForm = lazy(() => import("./components/Invoices/InvoiceForm"));
const InvoicesManagment = lazy(() => import("./components/Invoices/InvoicesManagment"));
const InstallmentsManagment = lazy(() => import("./components/Installments/InstallmentsManagment"));
const InstallmentPay = lazy(() => import("./components/Installments/_InstallmentPay"));
const InvoicesStatement = lazy(() => import("./components/Statements/Invoices/InvoicesStatement"));

const Items = lazy(() => import("./components/Items/Items"));

const Users = lazy(() => import("./components/AppSettings/UsersManagment/Users"));
const PassChange = lazy(() => import("./components/AppSettings/PassChange"));
const WhatsApp = lazy(() => import("./components/AppSettings/WhatsApp"));
const General = lazy(() => import("./components/AppSettings/General"));
const Lists = lazy(() => import("./components/AppSettings/Lists"));

const NotFound = lazy(() => import("./components/NotFound/NotFound"));


const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  typography: {
    fontFamily: "ReadexPro-Medium, Arial, sans-serif",
  },
});

function RTL(props) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}

function MainApp() {
  return (
    <UserProvider>
      <TokenRefresher />
      <ResponsiveDrawer>
        <Routes>
          <Route
            path="/" // dashboard
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <Dashboard />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/customers"
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <Customers />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/guarantors"
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <Guarantors />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/suppliers"
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <Suppliers />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/invoiceForm"
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <InvoiceForm />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/invoicesManagment"
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <InvoicesManagment />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/installmentsManagment"
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <InstallmentsManagment />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/_installmentPay/:invoiceId"
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <InstallmentPay />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/invoicesStatement"
            element={
              <ProtectedRoute
                roles={[5150, 1984]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <InvoicesStatement />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/items"
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <Items />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/Users"
            element={
              <ProtectedRoute
                roles={[5150]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <Users />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/pass-change" // تغيير كلمة المرور
            element={
              <ProtectedRoute
                roles={[5150, 1984, 2001]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <PassChange />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/whatsApp" // اعدادات ال whatsApp
            element={
              <ProtectedRoute
                roles={[5150]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <WhatsApp />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/general"
            element={
              <ProtectedRoute
                roles={[5150]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <General />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/lists"
            element={
              <ProtectedRoute
                roles={[5150]}
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <Lists />
                  </Suspense>
                }
              />
            }
          />
          <Route path="*" element={<ProtectedRoute element={<NotFound />} />} />
        </Routes>
      </ResponsiveDrawer>
    </UserProvider>
  );
}
function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RTL>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={arLocale}>
            <Routes>
              <Route
                path="/login"
                element={
                  <Suspense fallback={<CenteredProgressGreen />}>
                    <Login />
                  </Suspense>
                }
              />
              <Route path="*" element={<MainApp />} />
            </Routes>
          </LocalizationProvider>
        </RTL>
      </ThemeProvider>
    </Router>
  );
}

export default App;
