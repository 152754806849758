import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Toolbar,
  Grid,
  Typography,
  IconButton,
  ListItemIcon,
} from "@mui/material";

import {
  ExpandMore,
  Dashboard as DashboardIcon,
  ExpandLess as ExpandLessIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  Inventory as InventoryIcon,
  Category as CategoryIcon,
  ZoomOutMap as ZoomOutMapIcon,
  ZoomInMap as ZoomInMapIcon,
  Sell as SellIcon,
  AddShoppingCart as AddShoppingCartIcon,
  FactCheck as FactCheckIcon,
  Contacts as ContactsIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  SocialDistance as SocialDistanceIcon,
  Hail as HailIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
  ManageAccounts as ManageAccountsIcon,
  WhatsApp as WhatsAppIcon,
  Tune as TuneIcon,
  EditNote as EditNoteIcon,
  Password as PasswordIcon,
  Analytics as AnalyticsIcon,
  Receipt as ReceiptIcon,
  Event as EventIcon,
} from "@mui/icons-material";

import { UserContext } from "../../shared/_UserContext";
import Translations from "../../shared/_Translations";
import "./ResponsiveDrawer.css";

const serverURL = import.meta.env.VITE_REACT_APP_SERVER_URL;
const appVersion = process.env.APP_VERSION;

const drawerWidth = 175;

function ResponsiveDrawer(props) {
  const { user, setUser } = useContext(UserContext);
  let roleNumber = null;
  if (user.role && user.role[0] && user.role[0][0]) {
    roleNumber = user.role[0][0];
  }

  const navigate = useNavigate();

  const coverImagePath = `${serverURL}/enterps/logos/logo-1.png`;

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState([]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  //////////////////////////////////////////////////////////////////////
  const [isFullScreen, setIsFullScreen] = useState(false);
  // تعريف دالة للتبديل بين وضع ملء الشاشة والوضع العادي
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(e => {
        console.error(`Error attempting to enable full-screen mode: ${e.message} (${e.name})`);
      });
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };
  // تأكد من تحديث حالة ملء الشاشة إذا تم تغييرها خارجياً
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);
  //////////////////////////////////////////////////////////////////////

  const handleClick = index => {
    const newOpen = Array(sideBarItem.length).fill(false);
    newOpen[index] = !open[index];
    setOpen(newOpen);
  };

  const closeDrawer = () => {
    if (mobileOpen) {
      setMobileOpen(false);
    }
  };

  // ...(roleNumber !== 5150 ? [] : []), // للتحكم في العناصر حسب الادوار
  // 5150 مدير
  // 1984 مشرف
  // 2001 مستخدم

  const sideBarItem = [
    {
      text: "الرئيسية",
      href: "/",
      icon: <DashboardIcon color="warning" />,
      subItems: [],
    },
    {
      text: "المبيعات",
      icon: <SellIcon color="warning" />,
      subItems: [
        {
          text: "بيع",
          href: "/invoiceForm",
          icon: <AddShoppingCartIcon />,
        },
        {
          text: "فواتير البيع",
          href: "/invoicesManagment",
          icon: <FactCheckIcon />,
        },
        {
          text: "ادارة الاقساط",
          href: "/installmentsManagment",
          icon: <EventIcon />,
        },
        // {
        //   text: "Divider",
        // },
      ],
    },

    ...(roleNumber === 5150 || roleNumber === 1984
      ? [
          {
            text: "الكشوفات",
            icon: <AnalyticsIcon color="warning" />,
            subItems: [
              {
                text: "فواتير البيع",
                href: "/invoicesStatement",
                icon: <ReceiptIcon />,
              },
            ],
          },
        ]
      : []),

    {
      text: "المواد",
      icon: <InventoryIcon color="warning" />,
      subItems: [
        {
          text: "المواد",
          href: "/items",
          icon: <CategoryIcon />,
        },
      ],
    },

    {
      text: "العملاء",
      icon: <ContactsIcon color="warning" />,
      subItems: [
        {
          text: "الزبائن",
          href: "/customers",
          icon: <TransferWithinAStationIcon />,
        },
        {
          text: "الكفلاء",
          href: "/guarantors",
          icon: <SocialDistanceIcon />,
        },
        {
          text: "الموردين",
          href: "/suppliers",
          icon: <HailIcon />,
        },
      ],
    },

    {
      text: "الإعدادات",
      icon: <SettingsIcon color="warning" />,
      subItems: [
        ...(roleNumber === 5150
          ? [
              {
                text: "عامة",
                href: "/general",
                icon: <TuneIcon />,
                subItems: [],
              },
              {
                text: "المستخدمين",
                href: "/users",
                icon: <ManageAccountsIcon />,
                subItems: [],
              },
              {
                text: "WhatsApp",
                href: "/whatsApp",
                icon: <WhatsAppIcon />,
                subItems: [],
              },
              {
                text: "قوائم الحقول",
                href: "/lists",
                icon: <EditNoteIcon />,
                subItems: [],
              },
            ]
          : []),
        {
          text: "كلمة المرور",
          href: "/pass-change",
          icon: <PasswordIcon />,
          subItems: [],
        },
      ],
    },
    {
      text: "الخروج",
      logout: true,
      icon: <LogoutIcon color="warning" />,
      subItems: [],
    },
  ];

  const drawer = (
    <>
      <Toolbar className="right-toolbar">
        <Grid container alignItems="center">
          <Grid item flexGrow={1}>
            <Typography sx={{ fontSize: "14px" }}>
              اهلا:{" "}
              {user && user.DisplayName && user.DisplayName.length > 10
                ? `${user.DisplayName.substring(0, 8)}...`
                : user.DisplayName || "مجهول"}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              الدور: {Translations[user.role] || user.role}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <Box sx={{ overflow: "auto" }}>
        <List>
          {sideBarItem.map((item, index) => (
            <React.Fragment key={index}>
              {item.subItems.length > 0 ? (
                <ListItemButton onClick={() => handleClick(index)}>
                  <ListItemIcon sx={{ minWidth: 32 }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                  {open[index] ? <ExpandLessIcon /> : <ExpandMore />}
                </ListItemButton>
              ) : (
                <ListItemButton
                  onClick={
                    item.logout
                      ? () => {
                          localStorage.removeItem("smarto24token");
                          setUser({});
                          navigate("/login");
                          closeDrawer();
                        }
                      : () => {
                          navigate(item.href);
                          closeDrawer();
                        }
                  }
                  component={RouterLink}
                  to={item.href}>
                  <ListItemIcon sx={{ minWidth: 32 }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              )}
              {item.subItems.map((subItem, subIndex) => (
                <Collapse in={open[index]} timeout="auto" unmountOnExit key={subIndex}>
                  <List component="div" disablePadding>
                    {subItem.text === "Divider" ? (
                      <Divider sx={{ width: "75%", margin: "auto" }} />
                    ) : (
                      <ListItemButton
                        component={RouterLink}
                        to={subItem.href}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          setMobileOpen(false);
                        }}>
                        <ListItemIcon sx={{ minWidth: 32 }}>{subItem.icon}</ListItemIcon>
                        <ListItemText primary={subItem.text} />
                      </ListItemButton>
                    )}
                  </List>
                </Collapse>
              ))}
              <Divider />
            </React.Fragment>
          ))}
          <Typography sx={{ mt: 2, textAlign: "center", fontSize: 14, color: "#9c27b9" }}>
            Smarto24 v{appVersion}
          </Typography>
        </List>
      </Box>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}>
        <Toolbar className="left-toolbar">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon />
          </IconButton>
          <Typography noWrap component="div" sx={{ flexGrow: 1 }}>
            {user.EnterpName}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <IconButton
            onClick={toggleFullScreen}
            sx={{
              color: "white",
              display: {
                xs: "none",
                sm: "none",
                md: "inline-flex",
                lg: "inline-flex",
                xl: "inline-flex",
              },
            }}>
            {isFullScreen ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
          </IconButton>

          <img src={coverImagePath} alt="Logo" style={{ maxHeight: "40px" }} />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              position: "relative",
              width: drawerWidth,
            },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}>
        <Toolbar />
        <Box
          sx={{
            width: "100%",
            display: "table",
            tableLayout: "fixed",
            boxShadow: 2,
            padding: 1,
            borderRadius: 1,
          }}>
          {" "}
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
